import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Row extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    nested: PropTypes.bool,
    noMargin: PropTypes.bool,
    vCenter: PropTypes.bool,
    hCenter: PropTypes.bool,
    onClick: PropTypes.func,
  };

  static defaultProps = {
    id: '',
    nested: false,
    noMargin: false,
    vCenter: false,
    hCenter: false,
    onClick: () => { },
  };

  get classNames() {
    const { className, nested, noMargin, vCenter, hCenter } = this.props;
    return classNames('row', {
      [className]: className,
      'nested': nested,
      'no-margin': noMargin,
      'v-center': vCenter,
      'h-center': hCenter
    });
  }

  render() {
    const { id, children, onClick } = this.props;
    return(
      <div
        id={id}
        className={this.classNames}
        onClick={onClick}
      >{children}</div>
    );
  }
}
