import pluralize from 'pluralize';
import Response from './response';

export default class Query {
  constructor(klass) {
    this._klass = klass;
  }

  perPage = (perPage) => {
    this._perPage = perPage;
    return this;
  }

  page = (page) => {
    this._page = page;
    return this;
  }

  where = (q) => {
    this._q = { ...this._q, ...q };
    return this;
  }

  sort = (sort) => {
    this._q = { ...this._q, s: sort };
    return this;
  }

  url = (url) => {
    this._url = url;
    return this;
  }

  params = (params) => {
    this._params = params;
    return this;
  }

  assign = (props) => {
    Object.assign(this._klass, props);
    return this;
  }

  klassName = () => {
    return (new this._klass()).constructor.name;
  }

  idField = () => {
    return this._klass.ID_FIELD || 'id';
  }

  /**
   * find - Returns a promise that resolves to the API result
   * @param {number, string} id - ID belonging to the resource to load
   */
  find = (id) => {
    return new Promise((resolve, reject) => {
      if (!id) {
        console.log('Error', `${this.klassName()}.${this.idField()} is required for find.`);
        // Return empty object instead of rejecting to prevent unhandled exceptions
        resolve(new this._klass({}));
        return;
      }

      this.get(id).then((response) => {
        resolve(new this._klass(response));
      }).catch(reject);
    })
  }

  /**
   * all - Returns a promise that resolves to the API results with or without pagination
   * @param {bool} [pagination=true] - whether to include pagination
   * @returns {Promise} Promise object that contains API call results
   */
  all = (pagination = true) => {
    return new Promise((resolve, reject) => {
      this.get().then((response) => {
        const results = this.buildObjects(response);

        if (pagination) {
          const paginatedResponse = new Response(
            response.total_entries,
            response.total_pages,
            response.previous_page,
            response.next_page,
            response.secondary_search,
            results,
          );
          resolve(paginatedResponse);
        } else {
          resolve(results);
        }
      }).catch(reject);
    });
  }

  /**
   * save - Returns a promise that resolves to the API results of save to the backend.
   * @param {object} obj - Object to be updated/created
   * @returns {Promise} - Promise object that contains API call results
   */
  save = (obj) => {
    let promise;

    if (obj[this.idField()]) {
      promise = this.patch(obj[this.idField()], obj);
    } else {
      promise = this.post(obj);
    }

    return new Promise((resolve, reject) => {
      promise.then((response) => {
        resolve(new this._klass(response));
      }).catch(reject);
    });
  }

  update = (obj, data) => {
    return new Promise((resolve, reject) => {
      const id = obj[this.idField()];

      if (!id) {
        const error = `${this.klassName()}.${this.idField()} is required for update.`;
        reject({
          responseText: error,
          responseJSON: {
            id: [error],
            obj,
            data,
          }
        });

        return;
      }

      this.patch(id, data).then((response) => {
        resolve(new this._klass(response));
      }).catch(reject);
    });
  }

  destroy = (obj) => {
    return new Promise((resolve, reject) => {
      const id = obj[this.idField()];

      if (!id) {
        const error = `${this.klassName()}.${this.idField()} is required for destroy.`;
        reject({
          responseText: error,
          responseJSON: {
            id: [error],
            obj,
          }
        });

        return;
      }

      this.delete(obj[this.idField()]).then(() => {
        resolve();
      }).catch(reject);
    });
  }

  get = (id) => {
    let url = this.requestURL

    if (id) {
      url = `${url}/${id}`;
    }

    return new Promise((resolve, reject) => {
      $.ajax({
        url,
        dataType: 'JSON',
        data: {
          per_page: this.calculatedPerPage,
          page: this._page || 1,
          q: this._q || {},
          ...this._params,
        },
        success: resolve,
        error: (error) => {
          console.log('Error: ', error);
          reject(error);
        },
      })
    });
  }

  post = (data) => {
    const objectName = this.responseObjectName;

    return new Promise((resolve, reject) => {
      $.ajax({
        url: this.requestURL,
        dataType: 'JSON',
        type: 'POST',
        data: {
          [objectName]: data,
          ...this._params,
        },
        success: resolve,
        error: (error) => {
          console.log('Error: ', error);
          reject(error);
        },
      })
    });
  }

  patch = (id, data) => {
    const objectName = this.responseObjectName;

    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${this.requestURL}/${id}`,
        contentType: 'application/json',
        dataType: 'JSON',
        type: 'PATCH',
        data: JSON.stringify({
          [objectName]: data,
          ...this._params,
        }),
        success: resolve,
        error: (error) => {
          console.log('Error: ', error);
          reject(error);
        },
      })
    });
  }

  delete = (id) => {
    return new Promise((resolve, reject) => {
      $.ajax({
        url: `${this.requestURL}/${id}`,
        dataType: 'JSON',
        type: 'DELETE',
        data: this._params,
        success: resolve,
        error: (error) => {
          console.log('Error: ', error);
          reject(error);
        },
      });
    });
  }

  get calculatedPerPage() {
    if (this._perPage === 0 || (this._perPage !== undefined && this._perPage !== null)) {
      return this._perPage;
    }

    return Number.MAX_SAFE_INTEGER;
  }

  get requestURL() {
    return this._url ? this._url : this._klass.classUrl();
  }

  buildObjects = (response) => {
    const objectName = this.responseObjectName;
    return (response[pluralize(objectName)] || []).map((o) => new this._klass(o));
  }

  get responseObjectName() {
    return this._klass.NAME;
  }
}
