import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Button extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    to: PropTypes.string,
    className: PropTypes.string,
    target: PropTypes.string,
    text: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.bool,
    active: PropTypes.bool,
    block: PropTypes.bool,
    size: PropTypes.oneOf(['xlg', 'lg', 'md', 'sm', 'xsm', 'custom']),
    color: PropTypes.oneOf(['primary', 'secondary', 'danger', 'secondary-danger', 'dark', 'secondary-dark', 'shadow', 'selectable']),
    onClick: PropTypes.func,
    method: PropTypes.oneOf([
      'post',
      'delete',
      'patch',
      'get',
      'POST',
      'DELETE',
      'PATCH',
      'GET'
    ]),
    data: PropTypes.any,
    ariaHidden: PropTypes.bool,
    format: PropTypes.string,
    onError: PropTypes.func,
    onSuccess: PropTypes.func,
    rounded: PropTypes.bool,
    circled: PropTypes.bool,
  }

  static defaultProps = {
    id: '',
    target: '',
    to: '#',
    size: 'sm',
    disabled: false,
    block: false,
    active: false,
    color: 'primary',
    onClick: function () { },
    ariaHidden: false,
    format: 'HTML',
    onError: () => { },
    onSuccess: () => { },
    rounded: false,
    circled: false,
  }

  get classNames() {
    const { size, disabled, active, color, block, icon, rounded, circled, className } = this.props;
    return classNames({
      'btn-xlg': size === 'xlg',
      'btn-lg': size === 'lg',
      'btn-md': size === 'md',
      'btn-sm': size === 'sm',
      'btn-xsm': size === 'xsm',
      'is-active': active,
      'is-block': block,
      'is-icon': icon,
      'is-danger': color === 'danger',
      'is-secondary': color === 'secondary',
      'is-secondary-danger': color === 'secondary-danger',
      'is-dark': color === 'dark',
      'is-secondary-dark': color === 'secondary-dark',
      'is-shadow': color === 'shadow',
      'is-rounded': rounded,
      'is-circled': circled,
      'is-selectable': !disabled && color === 'selectable',
      'is-disabled': disabled,
      [className]: className,
    });
  }

  handleClick = (e) => {
    const { to, format, data, method, onClick, onSuccess, onError } = this.props;
    if (to === '#' || format !== 'HTML') {
      e.preventDefault();
    }

    e.persist();
    onClick(e);

    if (format !== 'HTML') {
      $.ajax({
        url: to,
        dataType: format,
        method,
        data,
        success: onSuccess,
        error: onError,
      });
    }
  }

  render() {
    const { id, text, children, to, method, data, target, format } = this.props;
    return (
      <a
        id={id}
        href={(format === 'HTML' && to) || '#'}
        className={this.classNames}
        onClick={this.handleClick}
        data-method={(format === 'HTML' && method) || ''}
        data-data={data}
        target={target}
      >{text || children}</a>
    );
  }
}
