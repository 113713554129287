/**
 * Response - A query response object that contains pagination and results.
 */
export default class Response {
  /**
   * Response constructor
   * @param {int} totalEntries - Total number of entries based on per_page
   * @param {int} totalPages - Total number of pages based on per_page
   * @param {int} previousPage - Previous page
   * @param {int} nextPage - Next page
   * @param {Array} results - Results from the API call
   */
  constructor(totalEntries, totalPages, previousPage, nextPage, secondarySearch, results) {
    this.totalEntries = totalEntries;
    this.totalPages = totalPages;
    this.previousPage = previousPage;
    this.nextPage = nextPage;
    this.secondarySearch = secondarySearch;
    this.results = results;
  }
}