import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default class Col extends React.Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    noPadding: PropTypes.bool,
    offsetSmall: PropTypes.number,
    offsetMedium: PropTypes.number,
    offsetLarge: PropTypes.number,
    pullSmall: PropTypes.number,
    pullMedium: PropTypes.number,
    pullLarge: PropTypes.number,
    pushSmall: PropTypes.number,
    pushMedium: PropTypes.number,
    pushLarge: PropTypes.number,
    s: PropTypes.number,
    m: PropTypes.number,
    l: PropTypes.number,
  };

  static defaultProps = {
    id: '',
    noPadding: false,
    s: 0,
    m: 0,
    l: 0
  };

  get classNames() {
    const { className, noPadding, offsetSmall, offsetMedium, offsetLarge,
            pullSmall, pullMedium, pullLarge, pushSmall, pushMedium, pushLarge,
            s, m, l } = this.props;

    return classNames('col', {
      [`s${s}`]: s > 0,
      [`m${m}`]: m > 0,
      [`l${l}`]: l > 0,
      [`offset-s${offsetSmall}`]: offsetSmall > 0,
      [`offset-m${offsetMedium}`]: offsetMedium > 0,
      [`offset-l${offsetLarge}`]: offsetLarge > 0,
      [`pull-s${pullSmall}`]: pullSmall > 0,
      [`pull-m${pullMedium}`]: pullMedium > 0,
      [`pull-l${pullLarge}`]: pullLarge > 0,
      [`push-s${pushSmall}`]: pushSmall > 0,
      [`push-m${pushMedium}`]: pushMedium > 0,
      [`push-l${pushLarge}`]: pushLarge > 0,
      [className]: className,
      'no-pad': noPadding
    });
  }

  render() {
    const { id, children } = this.props;
    return(
      <div
        id={id}
        className={this.classNames}
      >{children}</div>
    );
  }
}
