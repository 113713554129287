import Query from './query';

export default class Model {
  constructor(props) {
    Object.assign(this, props);
  }

  /**
   * all - Retrieves all instances of the calling class
   * CAUTION: Do not use this for large data sets.
   */
  static all(pagination = false) {
    const query = new Query(this);
    return query.all(pagination);
  }

  /**
   * where - Sets the conditions for the query
   * @param {*} q - conditions ex: {name_eq: 'bla'}
   */
  static where(q) {
    const query = new Query(this);
    return query.where(q);
  }

  /**
   *  find - Retrieves an instance of the calling class specified by id.
   * @param {number, string} id - The instance to retrieve
   */
  static find(id) {
    const query = this.perPage(1);
    return query.find(id);
  }

  /**
   * perPage - Sets the perPage for the query
   * @param {int} perPage - Number of results perPage
   */
  static perPage(perPage) {
    const query = new Query(this);
    return query.perPage(perPage);
  }

  /**
   * page - Sets the page for the query
   * @param {int} page - Results page based on perPage
   */
  static page(page) {
    const query = new Query(this);
    return query.page(page);
  }

  /**
   * sort - Sets the order for the query
   * @param {string} sort - Example: 'name asc'
   * @returns {instanceof Query} An instance of query
   */
  static sort(sort) {
    const query = new Query(this);
    return query.where({ s: sort });
  }

  /**
   * url - An optional method for overriding the class set static URL
   * Useful when retrieving nested resources. (ex: /api/v1/job_posts/:id/sub_categories)
   * @param {string} url - url to make the call to
   */
  static url(url) {
    const query = new Query(this);
    return query.url(url);
  }

  /**
   * params - An optional method for adding params to the query
   * @param {object} params - key value pairs to add to the query
   */
  static params(params) {
    const query = new Query(this);
    return query.params(params);
  }

  /**
   * classUrl - Allows the class that extends Model to use a method instead of just
   * hard coding URL
   */
  static classUrl() {
    return this.URL;
  }

  /**
   * get - Wrapper for the get method on the query
   * @param {number} id - Optional: ID to look up
   */
  static get(id) {
    const query = new Query(this);
    return query.get(id);
  }

  static assign(props) {
    Object.assign(this, props);
    const query = new Query(this);
    return query.assign(props);
  }

  /**
   * data - Strips out instance functions for saving
   */
  get data() {
    let data = {};

    // Removes any attributes that are functions from being sent to the backend
    // Also removes errorMessages
    Object.keys(this).forEach((key) => {
      if (key === 'errorMessages') return;
      if (typeof this[key] === 'function') return;
      data[key] = this[key];
    });

    return data;
  }

  /**
   * url - Allows for the setting of a URL on an instance. Useful for save, destroy and update.
   * @param {string} url
   * @returns {Model} A model instance so that you can chain function calls. Ex: exp.url('/bla').save();
   */
  url(url) {
    this._url = url;
    return this;
  }

  /**
   * params - An optional method for adding params to the query
   * @param {object} params - key value pairs to add to the query
   * @returns {Model} A model instance so that you can chain function calls. Ex: exp.url('/bla').save();
   */
  params(params) {
    this._params = params;
    return this;
  }

  /**
   * update - Sends the specified attributes and their values to the backend for updating.
   * @param {Object} data - Object containing the data to update
   * @returns {Promise} Promise object that contains API call results
   */
  update(data) {
    const query = new Query(this.constructor);
    if (this._url) query.url(this._url);
    if (this._params) query.params(this._params);
    return query.update(this.data, data);
  }

  /**
   * save - Sends all attributes edited or not to the backend for saving.
   * Intelligently posts or patches depending on if id is present.
   * @returns {Promise} Promise object that contains API call results
   */
  save() {
    const query = new Query(this.constructor);
    if (this._url) query.url(this._url);
    if (this._params) query.params(this._params);
    return query.save(this.data);
  }

  /**
   * patch - Gives direct access to the query.js patch method
   * Useful when url does not follow the standard rest format
   * @returns {Promise} Promise object that contains API call results
   */
  patch(id) {
    const query = new Query(this.constructor);
    if (this._url) query.url(this._url);
    if (this._params) query.params(this._params);
    return query.patch(id, this.data);
  }

  /**
   * destroy - Deletes this from the back end.
   * @returns {Promise} Promise object that contains API call results
   */
  destroy() {
    const query = new Query(this.constructor);
    if (this._url) query.url(this._url);
    if (this._params) query.params(this._params);
    return query.destroy(this.data);
  }

  /**
   * delete - Direct access to the delete method of the query
   * @returns {Promise} Promise object that contains API call results
   */
  delete() {
    const query = new Query(this.constructor);
    if (this._url) query.url(this._url);
    if (this._params) query.params(this._params);
    return query.delete((this.data || {}).id);
  }

  /**
   * toString - Converts object to a string
   * @returns {String} Stringified version of object
   */
  toString() {
    return JSON.stringify(this.data);
  }
}